
import React from "react";
import { User } from "firebase/auth";

export interface OrderClientSecretWrapper  {
    clientSecret: string;
}
  
export interface IAppContext {
    pendingOrderID: string | undefined;
    setPendingOrderID?: React.Dispatch<React.SetStateAction<string | undefined>>;
    orderOptionsResponse: OrderClientSecretWrapper | undefined;
    setOrderOptionsResponse?: React.Dispatch<React.SetStateAction<OrderClientSecretWrapper | undefined>>;
    providerStripeID: string | undefined;
    setProviderStripeID?: React.Dispatch<React.SetStateAction<string | undefined>>;
    firebaseUser: User | null;
    setFirebaseUser?: React.Dispatch<React.SetStateAction<User | null>>;
  }
  
  export const defaultState = {
    pendingOrderID: undefined,
    orderOptionsResponse: undefined,
    providerStripeID: undefined,
    firebaseUser: null,
  };
  
  export const AppContext = React.createContext<IAppContext>(defaultState);