// Create a modern styled react component using Material UI react with a powerful image and a button that opens a link in a new tab
//
import React, { useEffect, useState } from "react";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";


// Get a random image from unsplash
// https://source.unsplash.com/random/800x600

interface BuyTicketCardProps {
    url: string;
    imageUrl: string;
    title: string;
}

const BuyTicketCard = ({url, imageUrl, title}: BuyTicketCardProps) => {
    return (
        <div>
            <Card elevation={12} onClick={() => {
                // open url in a new window
                window.open(url, "_blank");
            }}>
                <CardMedia
                    component="img" // https://material-ui.com/components/cards/#media
                    image={imageUrl} // Import result is the URL of your image
                    alt="image"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography></CardContent>
                <CardActions>
                    </CardActions>
                    </Card>
         </div>
    );
    };



export default BuyTicketCard;