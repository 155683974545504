import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import StripeWrapper from "../components/StripeWrapper";
import { CONFIGURATION } from "../config";
import CheckoutForm from "../components/CheckoutForm";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProductBuySection from "../components/ProductBuySection";
import LoginAnon from "../components/LoginAnon";
import { auth } from '../firebase/firebaseDB';
import UserDetails from "../components/UserDetails";
import { AppContext } from "../context/PaymentsAppContext";
import SendMagicLink from "../components/SendMagicLink";
import SignupAsProviderForm from "../forms/SignupAsProviderForm";
import CreateNewProductForm from "../forms/CreateNewProductForm";
import InitiateStripeLink from "../components/InitiateStripeLink";

interface ProductListingScreenProps {
  accountId?: string;
}

function ProductListingScreen({accountId}: ProductListingScreenProps) {

  const { firebaseUser } = React.useContext(AppContext);

  return (
    <>
    <h1>Marketplace</h1>
    <ProductBuySection />
    <h1>Create Seller Account Setup</h1>
    {firebaseUser && <UserDetails />}
    {!firebaseUser && false && <LoginAnon /> }
    {!firebaseUser && <SendMagicLink /> }
    {firebaseUser && <SignupAsProviderForm />}
    {firebaseUser && <InitiateStripeLink /> }
    {firebaseUser && <CreateNewProductForm /> }
    </>
  )
}

export default ProductListingScreen;