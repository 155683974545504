import React, { useEffect, useState } from "react";

import { RaceEvent, getRaceNightByName } from "../firebase/firebaseQueries";
import BuyTicketCard from "../components/BuyTicketCard";
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


import HeaderImage from '../images/ticketybooklogo.png'
import nightatracesimage from '../images/Header.png'

import { Typography } from "@mui/material";
import EventCard from "../components/EventCard";



function HomeScreen() {

  return (

<>
    <Container maxWidth="md">


<Stack spacing={6} alignItems='center'>


<img src={HeaderImage} alt="Ticketybook  Logo" width="50%" height="auto" />


<Typography variant="h3" textAlign={'center'}>
Ticketybook Stress Free Payments</Typography>

<Typography variant="h6" textAlign={'center'} >
  Ticketybook removes the hurdles from your night at the races.  Go pour yourself a Red Rum.  Grab a cup of coffee and a Seabiscuit.   Call up your Best Mate.  We'll handle the rest.  </Typography>

  <Typography variant="h2" textAlign={'center'} >
Events
</Typography>

</Stack>



<EventCard url="/cliftonville/nightattheraces2012s" imageUrl={nightatracesimage} title="Event" />


</Container>

<Container maxWidth="sm" style={{paddingTop: '4em'}}>




</Container>



</>

  )
}

export default HomeScreen;