
import { app } from '../firebase/firebaseDB';
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc, setDoc, query, where, DocumentData } from 'firebase/firestore/lite';
import { Product } from '../types/Product';
import { Seller } from '../types/Seller';

const getProducts = async () => {
    const orgs = collection(getFirestore(app), 'products');

    const docsSnap = await getDocs(orgs);

    return docsSnap.docs.map(x => x.data() as Product);
}

const addProduct = async (productToAdd: Product) => {
    const reports = collection(getFirestore(app), 'products');

    const newDocID = await addDoc(reports, productToAdd);

    return newDocID;
}

const getSellers = async () => {
    const orgs = collection(getFirestore(app), 'sellers');

    const docsSnap = await getDocs(orgs);

    return docsSnap.docs.map(x => x.data() as Seller);
}

const addSeller = async (sellerToAdd: Seller) => {
    const reports = collection(getFirestore(app), 'sellers');

    const newDoc = await addDoc(reports, sellerToAdd);
    
    sellerToAdd.sellerID = newDoc.id;

    await updateDoc(newDoc, {sellerID: newDoc.id})

    return newDoc;
}

const addSellerWithUserID = async (userID: string, sellerToAdd: Seller) => {
    const reports = collection(getFirestore(app), 'sellers');

    const docByID = doc(reports, userID);

    const newDoc = await setDoc(docByID, sellerToAdd);
    
   // sellerToAdd.sellerID = newDoc.id;

  //  await updateDoc(newDoc, {sellerID: newDoc.id})

    return newDoc;
}

export interface RaceEvent {
    horseDocs: { id: string; data: DocumentData; }[]; 
    raceDocs: { id: string; data: DocumentData; }[];
}

async function getSubCollectionDocs(parentDocId = 'cliftonville2012races') {
    try {
      // Define the parent document reference
      const parentDocRef = doc(getFirestore(app), "events", parentDocId);
  
      // Define the sub-collection reference
      const subCollectionRef = query(collection(parentDocRef, "races"), where('isTest', '==', false));
  
      // Get the sub-collection documents and sort by createdAt
    const querySnapshot = await getDocs(query(subCollectionRef));
      //const querySnapshot = await getDocs(query(subCollectionRef));

      // Process the results
      const docs: { id: string; data: DocumentData; }[] = [];
      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, data: doc.data() });
      });

    // Define the sub-collection reference
    const subCollectionRefHorses = query(collection(parentDocRef, "horses"), where('isTest', '==', false));


        //const prodHorses = query(horses, where('isTest', '==', false));
   // const prodRaces = query(races, where('isTest', '==', false));

    // Get the sub-collection documents
    const querySnapshotHorse = await getDocs(query(subCollectionRefHorses));

    // Process the results
    const horseDocs: { id: string; data: DocumentData; }[] = [];
    querySnapshotHorse.forEach((doc) => {
        horseDocs.push({ id: doc.id, data: doc.data() });
    });

      console.log("Sub-collection documents:", docs);
      return {raceDocs: docs, horseDocs: horseDocs};
    } catch (error) {
      console.error("Error fetching sub-collection documents:", error);
      alert(error);
      return [];
    }
  }


const getRaceNightByName = async (eventName = 'cliftonville2012races'): Promise<any> => {

    return await getSubCollectionDocs();
    /*

    const reports = collection(getFirestore(app), 'events');

    const docByID = doc(reports, eventName);





    // Get the horses collection from the events collection for the doc identified by eventName
   // const horses = collection(event, eventName, 'horses');
   // const races = collection(event, eventName, 'races');


    // Add in a filter to keep only docs where the isTest field is false.
    //const prodHorses = query(horses, where('isTest', '==', false));
   // const prodRaces = query(races, where('isTest', '==', false));

   // const horseCollection = await getDocs(horses);
   // const raceCollection = await getDocs(races);





    // Define the parent document reference
    const parentDocRef = doc(getFirestore(app), "events", eventName);

    // Define the sub-collection reference
    const subCollectionRef = collection(parentDocRef, "horses");

    // Get the sub-collection documents
    const querySnapshot = await getDocs(query(subCollectionRef));


    // Process the results
    const horseDocs: { id: string; data: DocumentData; }[] = [];
    querySnapshot.forEach((doc) => {
      horseDocs.push({ id: doc.id, data: doc.data() });
    });

    return {horseDocs};*/
}

export { getProducts, addProduct, getSellers, addSeller, addSellerWithUserID, getRaceNightByName }