import React, { useEffect, useState } from "react";

import { RaceEvent, getRaceNightByName } from "../firebase/firebaseQueries";
import BuyTicketCard from "../components/BuyTicketCard";
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import HorseImage from '../images/Horse.png';
import RaceImage from '../images/HorseRace.png'
import HeaderImage from '../images/Header.png'
import TicketybookImage from '../images/ticketybooklogo.png'

import { Typography } from "@mui/material";
import HorseTable from "../components/HorseTable";
import RaceTable from "../components/RaceTable";

interface PaymentScreenProps {
  accountId?: string;
}

function NightAtRaces({accountId}: PaymentScreenProps) {

  const [raceNight, setRaceNight] = useState<RaceEvent | undefined>();

  useEffect(() => {
    getRaceNightByName().then((raceNight) => {
      setRaceNight(raceNight);
    })
  }, []);


  return (

<>
    <Container maxWidth="md" >

    <Stack spacing={10} alignItems='center'>

    <img src={TicketybookImage} alt="Ticketybook  Logo" width="auto" height="100px" />



<img src={HeaderImage} alt="Cliftonville Logo" width="100%" height="auto" />


        </Stack>


</Container>


<Container maxWidth="sm" style={{paddingTop: '4em'}}>


<Stack spacing={7}>
  <Stack spacing={3}>

<Typography variant="h2" textAlign={'center'}>
  Sponsor a Horse for £10. 
  </Typography>

  <Typography variant="h5" textAlign={'center'}>
  Winning horse sponsor will win a prize.  
</Typography>

{raceNight && raceNight.horseDocs && (
<Typography variant="h4" textAlign={'center'}>
  <em>{64 - raceNight.horseDocs.length }</em> horses sponsor chances remaining
  </Typography>
)}

</Stack>
{raceNight && raceNight.horseDocs && raceNight.horseDocs.length < 64 && (
  <BuyTicketCard imageUrl={HorseImage} url="https://donate.stripe.com/14k9EygEjc6Jdxe7ss" title="Click here to sponsor a horse for £10"/>
)}

<div>
  <Typography variant="h2" textAlign={'center'}>
  Sponsor a Race for £50
  </Typography>

  <Typography variant="h5" textAlign={'center'}>
    Feature your company name as race sponsor. Put your company name as Your Name on the payment form.
  </Typography>

{raceNight && raceNight.raceDocs && (
<Typography variant="h4" textAlign={'center'}>
  <em>{8 - raceNight.raceDocs.length }</em> race sponsor chances remaining
  </Typography>
)}

</div>
{raceNight && raceNight.raceDocs && raceNight.raceDocs.length < 8 && (
  <BuyTicketCard imageUrl={RaceImage} url="https://donate.stripe.com/eVabMG73JgmZ1Ow5kl" title="Click here to sponsor a race for £50"/>
)}


</Stack>


</Container>

<Container maxWidth="sm" style={{paddingTop: '4em'}}>


<Stack spacing={4} alignItems='center'>



<h1>Race Names</h1>

{raceNight &&
<RaceTable races={raceNight.raceDocs}></RaceTable>}


<h1>Horse Names</h1>


{raceNight &&
<HorseTable horses={raceNight.horseDocs}></HorseTable>}


<img src={TicketybookImage} alt="Ticketybook  Logo" width="auto" height="100px" />

<Typography variant="h4" textAlign={'center'} >
  Ticketybook removes the hurdles from your night at the races.  Go pour yourself a Red Rum.  Grab a cup of coffee and a Seabiscuit.   Call up your Best Mate.  We'll handle the rest.  </Typography>

</Stack>

  



  </Container>



</>

  )
}

export default NightAtRaces;