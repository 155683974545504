import * as React from 'react';
import { AppContext, OrderClientSecretWrapper } from './context/PaymentsAppContext';
import { User } from 'firebase/auth';
import { auth } from './firebase/firebaseDB';
import { useLocation } from 'react-router-dom';
import { authEmailLinkHandler } from './firebase/authEmailLinkHandler';
import CssBaseline from '@mui/material/CssBaseline';
import NightAtRaces from './screens/NightAtRaces';
import ContactFooter from './components/ContactFooter';
import { Helmet } from "react-helmet";


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_51JhYpxHJBACkiYtb8Je6vymUi29I0P8cjHHVESKCzAi2aPJERRPlXibenbvZ80sP4y8oogY9tqMqbzLQVrpDSBPH00Ws8vIE8Q', { stripeAccount: 'acct_1LI9gfQde2d3vAd9'});

const demoStripeAccountID = 'acct_1LI9gfQde2d3vAd9';

function Cliftonville() {

  const [pendingOrderID, setPendingOrderID] = React.useState<string>();
  const [orderOptionsResponse, setOrderOptionsResponse] = React.useState<OrderClientSecretWrapper | undefined>();
  const [providerStripeID, setProviderStripeID] = React.useState<string | undefined>(demoStripeAccountID);
  const [firebaseUser, setFirebaseUser] = React.useState<User | null>(null);

  const onlyCliff = true;
  auth.onAuthStateChanged((authState) => {
    setFirebaseUser(authState);
  })

  let location = useLocation();

  React.useEffect(() => {
    authEmailLinkHandler()
  }, [location]);

  authEmailLinkHandler();

  return (
    <AppContext.Provider value={{
      pendingOrderID,
      setPendingOrderID,
      orderOptionsResponse,
      setOrderOptionsResponse,
      providerStripeID,
      setProviderStripeID,
      firebaseUser,
      setFirebaseUser,
    }}>
      <Helmet>
        <title>Cliftonville FC 2012s Fundraiser</title>
        <meta name="description" content="Cliftonville FC" />
      </Helmet>
      
      <CssBaseline />
    <NightAtRaces />
    <ContactFooter />
    </AppContext.Provider>
  )
};

export default Cliftonville;