// Create a modern styled react component using Material UI react with a powerful image and a button that opens a link in a new tab
//
import React, { useEffect, useState } from "react";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";

// Import histort from react router dom
import { useNavigate} from "react-router-dom";

// Get a random image from unsplash
// https://source.unsplash.com/random/800x600

interface EventCardProps {
    url: string;
    imageUrl: string;
    title: string;
}

const EventCard = ({url, imageUrl, title}: EventCardProps) => {

    const navigate = useNavigate();

    return (
        <div>
            <Card elevation={15} style={{marginTop: '50px'}}onClick={() => {
                // open url in a new window
               // window.open(url, "_blank");

                // Use react route dom to navigate to relative url in url
                navigate(url);
            }}>
                <CardMedia
                    component="img" // https://material-ui.com/components/cards/#media
                    image={imageUrl} // Import result is the URL of your image
                
                    alt="image"
                />
                    </Card>
         </div>
    );
    };



export default EventCard;