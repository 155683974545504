// Create a modern styled react component using Material UI react with a powerful image and a button that opens a link in a new tab
//
import React, { useEffect, useState } from "react";
import { Container, Typography, Stack } from "@mui/material";

const ContactFooter = () => {
    // Create MUI contact footer with email contact email link
    return (
        <Container style={{paddingTop: '20px', paddingBottom: '20px'}}>
            <Stack spacing={1} alignItems='center'>
                <Typography variant="h6" >
                Contact:
            </Typography>
            <a href="mailto:niall@bubbadigital.com">       <Typography variant="h6" >  
                niall@bubbadigital.com
            </Typography>
            </a>

         
            </Stack>
         </Container>
    );
    };



export default ContactFooter;