import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  raceName: string,
  sponsorName: string,
  raceNumber: number,
) {
  return { raceName, sponsorName, raceNumber };
}

// create a sort function that will compare by the numberical interpretation of the createdAt field
// then map the horses to rows
const sortByCreatedAt = (x: any, y: any) => {
  // parse date created at as a date
  const xDate = new Date(x.data.createdAt.seconds);
  const yDate = new Date(y.data.createdAt.seconds);
  // compare the dates
  return xDate > yDate ? 1 : -1;
}

/*const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];*/

interface RaceData {
    races: any[];
}

export default function RaceTable({races}: RaceData) {

    // mockup data in races making 4 new elements in the array
   // races.push({...races[0]})
    const rows = races.sort(sortByCreatedAt).map((race, index) => {
        return createData(race.data.raceName, race.data.yourName, index + 1);
});
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Race Name</TableCell>
            <TableCell align="right">Sponsor/Company Name</TableCell>
            <TableCell align="right">Race Number </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.raceName}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.raceName}
              </TableCell>
              <TableCell align="right">{row.sponsorName}</TableCell>
              <TableCell align="right">{row.raceNumber}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}