import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  horseName: string,
  sponsorName: string,
  raceNumber: number,
  silksNumber: number,
) {
  return { horseName, raceNumber, sponsorName, silksNumber};
}

/*const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];*/

interface HorsesData {
    horses: any[];
}

// create a sort function that will compare by the numberical interpretation of the createdAt field
// then map the horses to rows
const sortByCreatedAt = (x: any, y: any) => {
  // parse date created at as a date
  const xDate = new Date(x.data.createdAt.seconds);
  const yDate = new Date(y.data.createdAt.seconds);
  // compare the dates
  return xDate > yDate ? 1 : -1;
}

// Create a function that buckets horses in groups of 8 so horses 1 to 8 are in race 1
// and next 8 are in race 2 etc.
const getRealRaceNumber = (index: number) => {
  if (index < 8) return 1;
  return Math.floor(index / 8) + 1;
}

export default function HorseTable({horses}: HorsesData) {
    const rows = horses.sort(sortByCreatedAt).map((horse, index) => {
        return createData(horse.data.horseName, horse.data.yourName, getRealRaceNumber(index),  index % 8 + 1);
});
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Horse Name</TableCell>
            <TableCell align="right">Sponsor Name</TableCell>
            <TableCell align="right">Race Number </TableCell>
            <TableCell align="right">Silks Number </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.horseName}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.horseName}
              </TableCell>
              <TableCell align="right">{row.sponsorName}</TableCell>
              <TableCell align="right">{row.raceNumber}</TableCell>
              <TableCell align="right">{row.silksNumber}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}