// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAi3aZtbiNbV72RKMNmcHa0F3hfYJGIx8Y",
  authDomain: "ticketybook.firebaseapp.com",
  projectId: "ticketybook",
  storageBucket: "ticketybook.appspot.com",
  messagingSenderId: "626591268538",
  appId: "1:626591268538:web:272f0c79e3928e0d668c83",
  measurementId: "G-GS9XCH9R3C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { app, firestore, auth, analytics};